import React, { useState, useEffect } from 'react';
import { ApiProvider } from '../providers/ApiProvider';
import { useHistory } from 'react-router-dom';
import { error } from 'console';

/**
 *
 * @param paymentId
 * @param refresher Parameter used to trigger effect (Hack until better solution is found).
 * @param interval  Interval to clear if it's called from an interval
 */
export function useGetPayment(paymentId: string, refresher: number = 0, interval?: any) {
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [data, setData]: any = useState();
  const [paymentError, setPaymentError]: any = useState(null);
  const [isExpired, setIsExpired]: any = useState(null);
  const [isAlreadyDone, setIsAlreadyDone]: any = useState(null);
  const [pinRetries, setPinRetries]: any = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!paymentId) setPaymentError({ message: 'oopsy', loading: false });
    if (paymentId) {
      // Only set loading if it has no data, prevents page blinking
      if (!data) setPaymentLoading(true);

      ApiProvider.getPayment(paymentId)
        .then((data) => {
          console.log('useGetPayment.then', data);
          setData(data);
          setPaymentError(null);
          setPinRetries(data.retries);

          if (data.status === 'expired') {
            setIsExpired(true);
          } else if (data.status === 'done') {
            setIsAlreadyDone(true);
          } else if (data.status === 'failed') {
            setPaymentError({ message: 'Failed payment transactions' });
          }

          if (data.payment_type === 'mobile') {
            history.push(`/${paymentId}/pay`);
          }
        })
        .catch((error) => {
          if(error.status_text === 'Not Found') {
            setPaymentError({message: 'notfound'});
          }

          else setPaymentError(error);
        })
        .finally(() => setPaymentLoading(false));
    }

    return () => clearInterval(interval);
  }, [paymentId, refresher]);

  const isDesktopPayment = () => data && data.payment_type === 'desktop';

  return {
    paymentError,
    isExpired,
    isAlreadyDone,
    paymentLoading,
    pinRetries,
    data,
    isDesktopPayment,
  };
}
