import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetPayment } from '../hooks/useGetPayment';
import { CircularLoader } from '../components/Loader';
import { useTranslation } from 'react-i18next';

export function withPayment(ChildComp: React.ComponentType<any | string>) {
  return (props: any) => {
    const { paymentId }: any = useParams();
    const { t } = useTranslation('translation');
    const { paymentError, paymentLoading, data, isExpired, isAlreadyDone, isDesktopPayment, pinRetries } = useGetPayment(paymentId);

    const loading = (
      <CircularLoader>
        <h2 style={{ fontSize: '24px', fontWeight: 200 }} className="MuiCircularProgress-colorPrimary">
          {t('loading')}
        </h2>
      </CircularLoader>
    );
    const finalProps = {
      ...props,
      payment: {
        ...data,
        isExpired,
        isAlreadyDone,
        isDesktopPayment,
        pinRetries,
      },
      error: paymentError,
    };
    return paymentLoading ? loading : <ChildComp {...finalProps} />;
  };
}
