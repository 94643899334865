import { useStylesModal } from '../common-styles';
import React, { useState, Props } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from '@material-ui/core';
import PinInput from 'react-pin-input';
import { useTranslation } from 'react-i18next';

export interface PinDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  pinHasError: boolean;
  handleClose: () => void;
  onSubmit: (pin: string) => void;
}

export const PinDialog = (props: PinDialogProps) => {
  const {
    isOpen,
    setIsOpen,
    pinHasError,
    handleClose,
    onSubmit,
  } = props;

  const classesModal = useStylesModal();
  const { t } = useTranslation('translation');
  const theme = useTheme();

  return (
    <Dialog
      className={classesModal.modal}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="enter pin dialog"
      aria-describedby="enter your pin"
    >
      <DialogTitle id="confirmation-dialog-title">
        {t('enterPin')}
      </DialogTitle>
      <DialogContent dividers>
        {pinHasError ? (
          <p style={{ color: theme.palette.error.main, textAlign: 'center' }}>
            {t('incorrectPin')}
          </p>
        ) : null}
        <form autoComplete="off">
          <PinInput
            length={4}
            initialValue=""
            secret
            focus
            onChange={(value, index) => {}}
            type="numeric"
            style={{ padding: '6px' }}
            inputStyle={{
              borderColor: '#ddd',
              width: '35px',
              height: '35px',
              borderRadius: '3px',
            }}
            inputFocusStyle={{ borderColor: theme.palette.primary.main }}
            onComplete={(pin, index) => onSubmit(pin)}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};
