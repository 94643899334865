import React from 'react';
import { Zoom, Paper, Box, Button, useTheme } from '@material-ui/core';
import { LangSelector } from '../components/LangSelector';
import { isBrowserMobile } from '../utils';
import { Logo } from '../components/Logo';
import { useTranslation, withTranslation } from 'react-i18next';
import { AuthProvider } from '../providers/AuthProvider';
import { useHistory, useParams } from 'react-router-dom';
import config from '../config';
import theme from '../styles/theme/theme';



export function _BasePage({
  children,
  childrenBelow,
  color = theme.palette.primary.main,
  headerMessage,
  testId,
}: any) {
  const { t } = useTranslation('translation');
  const { paymentId }: any = useParams();
  const history = useHistory();

  const logout = () => {
    AuthProvider.signout();
    history.push(`/${paymentId}`);
  };

  return (
    <Zoom in={true} mountOnEnter unmountOnExit>
      <div className={`login page`} data-testid={testId}>
        {/* <LangSelectorAbsolute /> */}
        <div className="floatRight row">
          <LangSelector />
          {AuthProvider.isAuthenticated() && (
            <Button onClick={logout}>{t('logOut')}</Button>
          )}
        </div>

        <Paper
          style={{
            width: isBrowserMobile() ? '100vw' : '500px',
            background: 'white',
          }}
        >
          <Box
            bgcolor={color}
            className="column center"
            style={{
              position: 'relative',
              padding: '30px 25px 30px 25px',
              color: 'white',
              borderRadius: '2px',
            }}
          >
            <Logo type="white" />
            {headerMessage && (
              <p
                color="white"
                className="small"
                style={{
                  textAlign: 'center',
                  marginTop: '25px',
                  fontSize: '12px',
                }}
              >
                {t(headerMessage)}
              </p>
            )}
          </Box>

          <div style={{ padding: '20px' }}>{children}</div>
        </Paper>
        {childrenBelow && (
          <div style={{ marginTop: '15px' }}>{childrenBelow}</div>
        )}
        <p style={{ marginTop: '15px', color: theme.palette.primary.main}}>
          Version: {config.version}
        </p>
      </div>
    </Zoom>
  );
}

export const BasePage = withTranslation('translation')(_BasePage);
