import { Fetcher } from './Fetcher';
import config from '../config';

export const AuthProvider = {
  login(username: string, password: string) {
    console.log('login', { username, password });
    return fetch(`${config.apiBase}/oauth/v3/token`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        client_id: config.clientID,
        client_secret: config.clientSecret,
        grant_type: 'password',
        password,
        username: username.trim(),
        version: '1',
        platform: 'rec-pos',
      }),
    }).then(async (resp) => {
      console.log('login then');
      if (resp.status > 300) {
        throw await resp.json();
      }
      return resp.json();
    });
  },
  isAuthenticated: () => {
    const hasToken = !!AuthProvider.getToken();
    const expireDate = Number(AuthProvider.getExpireDate());

    const now = Date.now();
    const diff = expireDate - now;
    console.log('diff', diff);

    const fiveMinutes = 60 * 5 * 1000;

    return hasToken && diff > fiveMinutes;
  },
  authenticate(token: string, expiresInSeconds: number) {
    let expireDate = new Date().getTime() + expiresInSeconds * 1000;
    localStorage.setItem('token', token);
    localStorage.setItem('expireDate', String(expireDate));
  },
  signout() {
    console.log('signing out');
    localStorage.removeItem('token');
  },
  getToken() {
    return localStorage.getItem('token');
  },
  getExpireDate() {
    return localStorage.getItem('expireDate');
  },
};
