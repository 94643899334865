import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPayment } from '../hooks/useGetPayment';
import { CircularLoader } from '../components/Loader';
import { useTranslation } from 'react-i18next';
import { useInterval } from '../hooks/useInterval';
import config from '../config';

export function withPolledPayment(
  ChildComp: React.ComponentType<any | string>
) {
  return (props: any) => {
    const { paymentId } = useParams();
    const [count, setCount] = useState(0);
    const { t } = useTranslation('translation');

    let interval = useInterval(() => {
      setCount(count + 1);
    }, config.pollingInterval);

    const {
      paymentError,
      paymentLoading,
      data,
      isExpired,
      isAlreadyDone,
      pinRetries,
      isDesktopPayment,
    } = useGetPayment(paymentId, count, interval);

    console.log('withPolledPayment', {
      paymentError,
      paymentLoading,
      data,
      isExpired,
      isAlreadyDone,
    });

    const loading = (
      <CircularLoader>
        <h2
          style={{ fontSize: '24px', fontWeight: 200 }}
          className="MuiCircularProgress-colorPrimary"
        >
          {t('loading')}
        </h2>
      </CircularLoader>
    );
    const finalProps = {
      ...props,
      payment: {
        ...data,
        isExpired,
        isAlreadyDone,
        isDesktopPayment,
        pinRetries,
      },
      error: paymentError,
    };
    return paymentLoading ? loading : <ChildComp {...finalProps} />;
  };
}
