import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import FLAG_EN from '../assets/flags/flags-iso/flat/24/GB.png';
import FLAG_ES from '../assets/flags/flags-iso/flat/24/ES.png';
import FLAG_CA from '../assets/flags/flags-iso/flat/24/CA.png';
import { useTranslation } from 'react-i18next';

const langMap: any = {
  es: 'ES',
  en: 'GB',
  ca: 'CA',
};

const langNameMap: any = {
  es: 'ESP',
  en: 'ENG',
  ca: 'CAT',
};

export function LangSelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t, i18n } = useTranslation('translation');
  const [lang, setLang] = useState('es');

  const changeLanguage = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  };

  const handleClick = (event: any) =>
    setAnchorEl(event.currentTarget);

  const handleClose = (event?: any) => setAnchorEl(null);

  const selectItem = (lang: any) => () => {
    changeLanguage(lang);
    handleClose();
  };

  const getFlag = (lang: string) => {
    switch (lang.toUpperCase()) {
      case 'ES':
        return FLAG_ES;
      case 'CA':
        return FLAG_CA;
      case 'EN':
      case 'GB':
        return FLAG_EN;
    }
  };

  const menuItem = (lang: string) => {
    return (
      <MenuItem key={lang} onClick={selectItem(lang)}>
        <img src={getFlag(lang)} />
        <span style={{ marginLeft: '10px' }}>
          {langNameMap[lang]}
        </span>
      </MenuItem>
    );
  };

  return (
    <div>
      <Button onClick={handleClick}>
        <img src={getFlag(langMap[i18n.language] || 'es')} />
        <span style={{ marginLeft: '10px' }}>
          {langNameMap[i18n.language]}
        </span>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {['es', 'en', 'ca'].map(menuItem)}
      </Menu>
    </div>
  );
}

export function LangSelectorAbsolute() {
  return (
    <div className="floatRight">
      <LangSelector />
    </div>
  );
}
