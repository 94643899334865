import React from 'react';
import { Link, useTheme } from '@material-ui/core';
import { isBrowserMobile } from '../utils';
import { logo } from '../styles/theme/theme';

export const Logo = ({ type = 'large' }) => {
  const isMobile = isBrowserMobile();
  const theme = useTheme();
  return (
    <Link href="https://rec.barcelona/es/inicio/" target="_blank">
      <img
        alt="logo"
        style={{
          width: isMobile ? '250px' : '250px',
          padding: '20px',
        }}
        src={type === 'white' ? logo.logo_white : logo.logo_large}
      />
    </Link>
  );
};
