import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import { getPaymentId } from '../utils';

export function AuthedRoute({ children, ...rest }: any) {
  const paymentId = getPaymentId();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        AuthProvider.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${paymentId}/login`,
            }}
          />
        )
      }
    />
  );
}
