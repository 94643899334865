import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { BasePage } from './BasePage';
import { RedirectCounter } from '../components/RedirectCounter';
import { withPolledPayment } from '../hoc/withPolledPayment';
import { QrPageHeader } from '../components/QrPageHeader';
import { QrPageFooter } from '../components/QrPageFooter';
import { QrPageAmount } from '../components/QrPageAmount';
import { PaymentQR } from '../components/PaymentQR';
import { MAX_PIN_RETRIES } from '../config/consts';
import { ErrorMessage, WarnMessage } from '../components/InfoMessages';
import { useTheme } from '@material-ui/core';

export function _QrPage(props: any) {
  const theme = useTheme();
  const { t } = useTranslation('translation');
  const { error, payment } = props;

  const content = payment && !error && (
    <div className="column center">
      <QrPageHeader account={payment.pos.account} />
      <QrPageAmount payment={payment} />
      <PaymentQR payment={payment} />
      <QrPageFooter payment={payment} />
    </div>
  );

  let color = theme.palette.primary.main;
  let children = content;

  if(payment.status === 'failed' && payment.pinRetries >= MAX_PIN_RETRIES){
    color = theme.palette.error.main;
    children = (
      <RedirectCounter route={payment ? payment.ko_url : ''}>
        <ErrorMessage message="ERROR_MAX_PIN_RETRIES"/>
      </RedirectCounter>
    );
  }
  else if (error) {
    color = theme.palette.error.main;
    children = (
      <RedirectCounter route={payment ? payment.ko_url : ''}>
        <ErrorMessage message={error.message}/>
      </RedirectCounter>
    );
  } else if (payment.isExpired) {
    color = theme.palette.warning.main;
    children = (
      <RedirectCounter route={payment && payment.ko_url}>
        <WarnMessage message="isExpired"/>
      </RedirectCounter>
    );
  } else if (payment.isAlreadyDone) {
    color = theme.palette.success.main;
    children = (
      <RedirectCounter route={payment && payment.ok_url}>
        <p style={{ color, textAlign: 'center' }}>
          {t('isAlreadyDone')}
        </p>
      </RedirectCounter>
    );
  }

  return (
    <BasePage
      testId="QRPage"
      color={color}
      children={children}
    ></BasePage>
  );
}

export const QrPage = withTranslation('translation')(
  withPolledPayment(_QrPage),
);
