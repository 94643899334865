import React from 'react';
import { ActiveGroupSelector } from './ActiveGroupSelector';
import { withTranslation, useTranslation } from 'react-i18next';
import { useStyles } from '../common-styles';
import { useAccounts } from '../hooks/useAccounts';
import { CircularLoader } from './Loader';
import { useAccount } from '../hooks/useAccount';

const _PayFormHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation('translation');
  const { accounts } = useAccounts();
  const { account, accountError, accountLoading } = useAccount();

  return (
    <div
      className={classes.spaceBetweenFlexRow}
      style={{ padding: '10px 20px' }}
    >
      <b className="mid">{t('selectedAccount')} </b>
      {accounts && account ? (
        <ActiveGroupSelector
          activeAccount={account}
          accounts={accounts}
        />
      ) : (
        <CircularLoader />
      )}
    </div>
  );
};

export const PayFormHeader = withTranslation('translation')(
  _PayFormHeader
);
