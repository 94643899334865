import React, { useState } from 'react';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import { useTranslation, withTranslation } from 'react-i18next';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import { AuthProvider } from '../providers/AuthProvider';
import { useParams, useHistory } from 'react-router-dom';
import { useStyles } from '../common-styles';
import { BasePage } from './BasePage';
import './Login.css';

function _LoginPage() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation('translation');
  const history = useHistory();

  const [username, setUsername]: any = useState('');
  const [password, setPassword]: any = useState('');
  const [error, setError]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { paymentId }: any = useParams();

  function login() {
    setIsLoading(true);
    AuthProvider.login(username, password)
      .then((resp) => {
        AuthProvider.authenticate(resp.access_token, resp.expires_in);
        history.push(`/${paymentId}/pay`);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  }

  const form = (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.title}>
        <h4 className="title" style={{color:theme.palette.primary.main}}>{t('loginTitle')}</h4>
        <p >{t('accessAccount')}</p>
      </div>

      <div className="input-group">
        <input
          id="username"
          name="username"
          type="text"
          className="form-control"
          placeholder={t('username')}
          autoComplete="new-password"
          value={username}
          onChange={(evt) => setUsername(evt.target.value)}
          disabled={isLoading}
        />
        <div className="input-group-addon">
          <AccountCircle />
        </div>
      </div>

      <div className="input-group">
        <input
          id="password"
          name="password"
          type="password"
          className="form-control"
          placeholder={t('password')}
          autoComplete="new-password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          disabled={isLoading}
        />
        <div className="input-group-addon">
          <Lock />
        </div>
      </div>

      {error && (
        <p
          style={{
            color: theme.palette.error.main,
            textAlign: 'center',
            fontSize: '12px',
          }}
        >
          {t(error.error_description || error.message || 'unkownError')}
        </p>
      )}

      <Button
        id="submit"
        style={{ marginTop: '25px' }}
        className="login-btn"
        color='primary'
        variant="contained"
        disableElevation
        disabled={!username || !password || isLoading}
        onClick={login}
      >
        {isLoading ? (
          <CircularProgress
            style={{ marginRight: '10px' }}
            size={20}
          />
        ) : null}
        {t('login')}
      </Button>
    </form>
  );

  return (
    <BasePage
      testId="LoginPage"
      color={error ? theme.palette.error.main : theme.palette.primary.main}
      children={form}
      headerMessage={'loginWarning'}
    ></BasePage>
  );
}

export const LoginPage = withTranslation('translation')(_LoginPage);
