import { amber, green, red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import logo_white_rosa from '../../../assets/logo/la_rosa/logo_white.png';
import logo_large_rosa from '../../../assets/logo/la_rosa/logo_large.png';
import logo_round_rosa from '../../../assets/logo/la_rosa/logo_round.png';

export const laROSATheme = createTheme({
    palette: {
        primary: {
          main: "#e61872",
        },
        secondary: {
            main: "#3B3B3B",
        },
        error: {
            main: red[400],
        },
        success: {
            main: green[400],
        },
        warning: {
            main: amber[800],
        }
      },
});

export const logosLarosa = {
    logo_large : logo_large_rosa,
    logo_white : logo_white_rosa,
    logo_round : logo_round_rosa,
    currency: 'R',
    title: 'laROSA TPV',
    favicon: 'img/favicon/larosa.png'
}