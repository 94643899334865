import React, { useEffect, useState } from 'react';
import { CircularLoader } from './Loader';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInterval } from '../hooks/useInterval';
import { AuthProvider } from '../providers/AuthProvider';
import { ONE_SEC, REDIRECT_ENABLED, TIMEOUT_MS } from '../config/consts';

export const RedirectCounter = ({ children, route, duration = TIMEOUT_MS }: any) => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(duration);
  const { t } = useTranslation('translation');

  useInterval(() => {
    if (timeLeft > ONE_SEC) setTimeLeft(timeLeft - ONE_SEC);
  }, ONE_SEC);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(!REDIRECT_ENABLED){
        console.warn('WARN: redirect skipped, but was set to redirect to: ' + route);
        return;
      }
      
      AuthProvider.signout();

      if (route) window.open(route, '_self');
      else history.length > 0 ? history.goBack(): history.push(`/`);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      {children}
      <CircularLoader>
        {
          <p>
            {t('redirectingIn')} <code>{timeLeft / ONE_SEC}s</code>
          </p>
        }
      </CircularLoader>
    </div>
  );
};
