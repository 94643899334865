import config from '../../config';
import { RecTheme, logosREC } from './rec/rec_theme';
import { laROSATheme, logosLarosa } from './la_rosa/la_rosa_theme';
import { whitelabelTheme, logosWhitelabel } from './whitelabel/whitelabel_theme';

export const defaultCurrency = 'rec';
export const availableCurrencies = ['rec', 'la_rosa', 'whitelabel'];
export let currency: string = config.type != '$POS_CURRENCY' ? config.type : defaultCurrency;

function currencyValidator() {
  if (!availableCurrencies.includes(currency) || typeof currency !== 'string') {
    console.warn('config.type is invalid, must be one of: [rec, la_rosa, whitelabel]');
    currency = availableCurrencies[0];
  }
}
currencyValidator();

const logosMap: any = {
  rec: logosREC,
  la_rosa: logosLarosa,
  whitelabel: logosWhitelabel,
};

export const logo = logosMap[currency];

export const ThemesMap: any = {
  rec: RecTheme,
  la_rosa: laROSATheme,
  whitelabel: whitelabelTheme,
};

export const theme = ThemesMap[currency];

if (document
   && (document.readyState == 'complete' || document.readyState == 'interactive') 
   && document.getElementsByTagName('title')[0] != null
   && document.getElementById('favicon') != null) {
  //set main color on css variables
  document.body.style.setProperty('--main-color', theme.palette.primary.main);

  // title y favicon de la tpv
  document.getElementsByTagName('title')[0].innerText = logo.title;
  document.getElementById('favicon')?.setAttribute('href', logo.favicon);
}

export default theme;
