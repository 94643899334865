import { MenuItem, Button, Menu } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { AccountPreview } from './AccountPreview';
import { ApiProvider } from '../providers/ApiProvider';

export const ActiveGroupSelector = ({
  activeAccount,
  accounts,
  setAccount,
}: any = {}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [group, setGroup] = useState(activeAccount.group_data);

  const handleClose = () => setAnchorEl(null);
  const handleClick = ({ currentTarget }: any) =>
    setAnchorEl(currentTarget);

  const selectAccount = (group: any) => {
    ApiProvider.selectAccount(group.id).then((resp: any) => {
      handleClose();
      setGroup(group);
    });
  };

  const mapAccount = (el: any, index: number) => {
    return (
      <MenuItem
        data-testid="account-menu-item"
        onClick={() => selectAccount(el)}
        key={index}
      >
        <AccountPreview
          group={el}
          trimText={false}
          showBalance="true"
          key={index + '-preview'}
        />
      </MenuItem>
    );
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ maxWidth: '300px' }}
        data-testid="selectedAccountPreview"
      >
        <AccountPreview
          showBalance="true"
          group={group}
          trimText={true}
        />
        <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="selectedAccountMenu"
      >
        {accounts && accounts.map(mapAccount)}
      </Menu>
    </div>
  );
};
