import amber from '@material-ui/core/colors/amber';

export const warnCommonStyle: any = {
  color: amber[800],
  textAlign: 'center',
  fontWeight: 400,
};

export const warnTitleStyle: any = {
  ...warnCommonStyle,
  fontWeight: 'bold',
};
