import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorCommonStyle, errorTitleStyle } from '../styles/errors';
import { warnCommonStyle, warnTitleStyle } from '../styles/warnings';


export interface InfoMessageProps {
  message:string;
  title: string;
  titleStyle:any;
  messageStyle: any;
}

export const InfoMessage = ({ message, title, titleStyle, messageStyle }: InfoMessageProps) => {
  const { t } = useTranslation('translation');
  
  return (
    <>
      <h2 style={titleStyle}>{t(title)}</h2>
      <p style={messageStyle}>
        {t(message)}
      </p>
    </>
  );
};

export const ErrorMessage = ({ message }: {message:string}) => (
  <InfoMessage 
    message={message} 
    title="ERROR" 
    messageStyle={errorCommonStyle} 
    titleStyle={errorTitleStyle}/>
);

export const WarnMessage = ({ message }: {message:string}) => (
  <InfoMessage 
    message={message} 
    title="WARNING" 
    messageStyle={warnCommonStyle} 
    titleStyle={warnTitleStyle}/>
);
