import { amber, blue, green, red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import logo_white_whitelabel from '../../../assets/logo/whitelabel/logo_white.png';
import logo_large_whitelabel from '../../../assets/logo/whitelabel/logo_large.png';
import logo_round_whitelabel from '../../../assets/logo/whitelabel/logo_round.png';

export const whitelabelTheme = createTheme({
    palette: {
        primary: {
          main: "#8C4F2B",
        },
        secondary: {
            main: "#3B3B3B",
        },
        error: {
            main: red[400],
        },
        success: {
            main: green[400],
        },
        warning: {
            main: amber[800],
        },
      },
});

export const logosWhitelabel = {
    logo_large : logo_large_whitelabel,
    logo_white : logo_white_whitelabel,
    logo_round : logo_round_whitelabel,
    currency: 'R',
    title: 'MONEDA LOCAL TPV',
    favicon: 'img/favicon/moneda_local.png'
}