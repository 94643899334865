import { useTheme } from '@material-ui/core';
import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const _QrPageFooter = ({ payment }: any) => {
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <div style={{ width: '250px', marginBottom: '20px' }}>
      <p className="small align-center">
        <span
          dangerouslySetInnerHTML={{
            __html: t('helpQR'),
          }}
        ></span>
        <span>
          <a
            href="javascript:;"
            style={{ color: theme.palette.primary.main, marginLeft: '4px' }}
            onClick={() => history.push(`/${payment.id}/login`)}
          >
            {t('login')}
          </a>
        </span>
      </p>
      {/* {payment.isDesktopPayment() ? (
        <div className="row center">
          <Button
            onClick={() => history.push(`/${payment.id}/login`)}
            color="primary"
          >
            Login
          </Button>
        </div>
      ) : null} */}
      <p
        className="small align-center"
        style={{
          paddingBottom: '8px',
          color: '#888',
          textAlign: 'center',
        }}
        dangerouslySetInnerHTML={{
          __html: t('expiresIn'),
        }}
      ></p>
    </div>
  );
};

export const QrPageFooter = withTranslation('translation')(
  _QrPageFooter,
);
