import { useEffect, useState } from 'react';
import { AuthProvider } from '../providers/AuthProvider';
import { ApiProvider } from '../providers/ApiProvider';

export function useAccount() {
  const [account, setAccount]: any = useState();
  const [accountError, setAccountError]: any = useState(null);
  const [accountLoading, setAccountLoading]: any = useState(null);

  useEffect(() => {
    if (AuthProvider.isAuthenticated()) {
      setAccountLoading(true);
      ApiProvider.getAccount()
        .then((resp) => {
          setAccount(resp);
          setAccountError(null);
          setAccountLoading(false);

          if(!resp.has_pin) {
            setAccount(null);
            setAccountError({message: 'pin_not_set'});
          }
        })
        .catch((error) => {
          setAccountError(error);
          setAccountLoading(false);
        });
    }
  }, []);

  return { account, setAccount, accountError, accountLoading };
}
