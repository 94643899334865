import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  centerFlexColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  centerFlexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  spaceBetweenFlexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '80%',
    },
    padding: '20px 15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '25px',
    '& > p': {
      margin: 0,
      padding: 0,
    },
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '10px',
  },
}));

export const useStylesModal = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
    borderRadius: '5px',
    border: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
