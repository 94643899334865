export const errorCommonStyle: any = {
  color: 'red',
  textAlign: 'center',
  fontWeight: 400,
};

export const errorTitleStyle: any = {
  ...errorCommonStyle,
  fontWeight: 'bold',
};
