import { useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { AccountPreview } from './AccountPreview';

const _QrPageHeader = ({ account }: any) => {
  const theme = useTheme();
  const { t } = useTranslation('translation');
  return (
    <div className="column center">
      <span
        style={{
          paddingBottom: '8px',
          color: theme.palette.primary.main,
          textTransform: 'uppercase',
        }}
      >
        {t('beneficiari')}
      </span>
      <div>
        <AccountPreview group={account} />
      </div>
    </div>
  );
};

export const QrPageHeader = withTranslation('translation')(
  _QrPageHeader,
);
