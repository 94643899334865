import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loader = () => (
  <div className="loading">
    <CircularProgress />
  </div>
);

export const CircularLoader = ({ children }: any) => (
  <div className="row center">
    <CircularProgress
      color="primary"
      style={{ margin: '10px' }}
      size={35}
    />
    {children}
  </div>
);
