import { amber, blue, green, red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import logo_large_rec from '../../../assets/logo/rec/logo_large.png';
import logo_white_rec from '../../../assets/logo/rec/logo_white.png';
import logo_round_rec from '../../../assets/logo/rec/logo_round.png';

export const RecTheme = createTheme({
    palette: {
        primary: {
          main: blue[400],
        },
        secondary: {
            main: "#3B3B3B",
        },
        error: {
            main: red[400],
        },
        success: {
            main: green[400],
        },
        warning: {
            main: amber[800],
        },
      },
});

export const logosREC = {
    logo_large : logo_large_rec,
    logo_white : logo_white_rec,
    logo_round : logo_round_rec,
    currency: 'R',
    title: 'REC TPV',
    favicon: 'https://rec.barcelona/wp-content/uploads/2018/04/favicon_16_rec_simbol.png'
}