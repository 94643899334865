import config from '../config';

export const Fetcher = {
  request(path: string, options: RequestInit) {
    return fetch(`${config.apiBase}${path}`, options).then(
      async (resp) => {
        let data = await resp.json();
        if (resp.status < 400) {
          return data.data;
        } else {
          return Promise.reject(data);
        }
      }
    );
  },
  get(path: string, options: RequestInit = {}) {
    return Fetcher.request(path, options);
  },
  post(path: string, options: RequestInit = {}) {
    return Fetcher.request(path, { ...options, method: 'POST' });
  },
  put(path: string, options: RequestInit = {}) {
    return Fetcher.request(path, { ...options, method: 'PUT' });
  },
};
