import React, { useState } from 'react';
import { CircularProgress, Button, Link, useTheme } from '@material-ui/core';
import { useTranslation, withTranslation } from 'react-i18next';
import { ApiProvider } from '../providers/ApiProvider';
import { useStyles } from '../common-styles';
import { CircularLoader } from '../components/Loader';
import { BasePage } from './BasePage';
import { RedirectCounter } from '../components/RedirectCounter';
import { useAccount } from '../hooks/useAccount';
import { AuthProvider } from '../providers/AuthProvider';
import { withPayment } from '../hoc/withPayment';
import { PinDialog } from '../dialogs/PinDialog';
import { PayFormBody } from '../components/PayFormBody';
import { PayFormHeader } from '../components/PayFormHeader';
import { MAX_PIN_RETRIES } from '../config/consts';
import { ErrorMessage, WarnMessage } from '../components/InfoMessages';


function PayForm(props: any) {
  const theme = useTheme();
  const { t } = useTranslation('translation');
  const [isPaid, setIsPaid]: any = useState(null);
  const [open, setOpen] = useState(false);
  const [pinError, setPinError] = useState(null);
  const [noFundsError, setNoFundsError] = useState(null);
  const [makePaymentError, setMakePaymentError] = useState(null);
  const [makePaymentLoading, setMakePaymentLoading]: any = useState(
    false,
  );
  const classes = useStyles();

  const { error: paymentError, payment } = props;
  const { account, accountError, accountLoading } = useAccount();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const errorMessage = (message: string | null) => (
    <p style={{ color: theme.palette.error.main, textAlign: 'center' }}>
      {t(message || '')}
    </p>
  );

  function makePayment(pin: string) {
    setMakePaymentLoading(true);

    ApiProvider.makePayment({
      concept: payment.concept,
      address: payment.payment_address,
      pin: pin,
      amount: Number(payment.amount),
    })
      .then(() => {
        setIsPaid(true);
        setMakePaymentLoading(false);
        setPinError(null);
      })
      .catch((err) => {
        console.log('err', err);
        // This is done like this as API does not return ERROR codes
        if (
          err.message === 'Incorrect Pin' ||
          err.message === 'Param PIN not found or incorrect'
        ) {
          setPinError(err.message || err.error_description);
          setNoFundsError(null);
          setMakePaymentError(null);
        } 
        else if (
          err.error === 'user_locked' ||
          err.error_description === 'Maximum pin attempts exceeded' ||
          err.error_description === 'User account is locked.'
        ){
          setMakePaymentError({message: err.message || err.error_description} as any);
          setPinError(null);
          setOpen(false);
          setNoFundsError(null);
        }
        else if (err.message === 'Not funds enough') {
          setNoFundsError(err.message);
          setPinError(null);
          setMakePaymentError(null);
          setOpen(false);
        } else {
          setPinError(null);
          setNoFundsError(null);
          setMakePaymentError(err);
        }
        setMakePaymentLoading(false);
      });
  }

  const isLoading = accountLoading || makePaymentLoading;
  const error = paymentError || accountError || makePaymentError;

  const content = isLoading ? (
    <CircularLoader />
  ) : (
    <div>
      {account && <PayFormHeader />}

      <hr />

      {payment && <PayFormBody payment={payment} />}

      <hr />

      {noFundsError ? errorMessage(noFundsError) : null}

      <div
        className={classes.centerFlexRow}
        style={{ padding: '20px' }}
      >
        <Button
          className="login-btn"
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleOpen}
        >
          {isLoading ? (
            <CircularProgress
              style={{ marginRight: '10px' }}
              size={20}
            />
          ) : null}
          {t('pay')}
        </Button>

        <PinDialog
          isOpen={open}
          setIsOpen={setOpen}
          handleClose={handleClose}
          onSubmit={makePayment}
          pinHasError={!!pinError}
        />
      </div>
    </div>
  );

  let children = content;
  let childrenBelow;
  let color = theme.palette.primary.main;
  
  if(payment.status === 'failed' && payment.pinRetries >= MAX_PIN_RETRIES){
    color = theme.palette.error.main;
    children = (
      <RedirectCounter route={payment ? payment.ko_url : ''}>
        <ErrorMessage message="ERROR_MAX_PIN_RETRIES"/>
      </RedirectCounter>
    );
  }
  else if (error) {
    color = theme.palette.error.main;
    children =
      pinError || noFundsError ? (
        errorMessage(t(error.message))
      ) : (
        <RedirectCounter route={payment ? payment.ko_url : ''}>
          <ErrorMessage message={error.message}/>
        </RedirectCounter>
      );
    childrenBelow = (
      <Link
        color="primary"
        href={payment && payment.ko_url}
        target="_self"
      >
        {t('returnToVendor')}
      </Link>
    );
  } else if (payment.isExpired) {
    color = theme.palette.warning.main;
    children = (
      <RedirectCounter route={payment.ko_url}>
        <WarnMessage message="isExpired"/>
      </RedirectCounter>
    );
  } else if (payment.isAlreadyDone) {
    color = theme.palette.success.main;
    children = (
      <RedirectCounter route={payment.ok_url}>
        <p style={{ color, textAlign: 'center' }}>
          {t('isAlreadyDone')}
        </p>
      </RedirectCounter>
    );
  } else if (isPaid) {
    AuthProvider.signout();
    color = theme.palette.success.main;
    children = (
      <RedirectCounter route={payment.ok_url}>
        <p style={{ color, textAlign: 'center' }}>
          {t('paySuccess')}
        </p>
      </RedirectCounter>
    );
  }

  return (
    <BasePage
      testId="PayPage"
      color={color}
      children={children}
      childrenBelow={
        (error ||
          payment.isExpired ||
          payment.isAlreadyDone ||
          isPaid) &&
        childrenBelow
      }
    ></BasePage>
  );
}

export const PayPage = withTranslation('translation')(
  withPayment(PayForm),
);
