import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { RestfulProvider } from 'restful-react';
import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from './Router';
import { Loader } from './components/Loader';
import config from './config';
import './style.css';
import { AuthProvider } from './providers/AuthProvider';
import { theme } from './styles/theme/theme';

export default function App() {
  window.addEventListener('beforeunload', (event) => {
    AuthProvider.signout();
  });

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <RestfulProvider base={config.apiBase} resolve={(data) => data}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </RestfulProvider>
      </ThemeProvider>
    </Suspense>
  );
}
