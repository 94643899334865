import React from 'react';
import { NonAuthedRoute } from './components/NonAuthedRoute';
import { LoginPage } from './pages/LoginPage';
import { Route, Switch } from 'react-router-dom';
import { AuthedRoute } from './components/AuthedRoute';
import { PayPage } from './pages/PayPage';
import { QrPage } from './pages/QrPage';
import { NotFoundPage } from './pages/NotFoundPage';

export const AppRouter = () => (
  <Switch>
    <NonAuthedRoute
      path="/:paymentId/login"
      children={<LoginPage />}
    />
    <Route path="/:paymentId/login" children={<LoginPage />} />
    <AuthedRoute path="/:paymentId/pay" children={<PayPage />} />
    <Route path="/:paymentId" children={<QrPage />} />
    <Route path="/" children={<NotFoundPage />} />
  </Switch>
);
