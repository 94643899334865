import React from 'react';
import { withTranslation } from 'react-i18next';
import { BasePage } from './BasePage';
import { ErrorMessage } from '../components/InfoMessages';
import { useTheme } from '@material-ui/core';


export function _NotFoundPage({ message }: any) {
  const theme = useTheme();
  const children = (
    <ErrorMessage message={message ?? 'notfound'}/>
  );

  return (
    <BasePage
      testId="NotFound"
      children={children}
      color={theme.palette.error.main}
      headerMessage=""
    ></BasePage>
  );
}

export const NotFoundPage = withTranslation('translation')(
  _NotFoundPage
);
