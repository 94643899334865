import { useStyles } from '../common-styles';
import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';
import { useTranslation, withTranslation } from 'react-i18next';
import { logo } from '../styles/theme/theme';

const _PayFormBody = ({ payment }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <div style={{ padding: '10px 20px' }}>
      <div style={{paddingBottom: '10px'}} className={classes.spaceBetweenFlexRow}>
        <b className="mid">{t('receiverAccount')}</b>
        <div className={classes.centerFlexRow}>
          <Avatar
            className={classes.avatarSmall}
            alt={payment.pos.account.name}
            src={payment.pos.account.public_image}
          />
          <p className="mid">{payment.pos.account.name}</p>
        </div>
      </div>
      <div style={{paddingBottom: '10px'}} className={classes.spaceBetweenFlexRow}>
        <b className="mid">{t('concept')}</b>
        <p className="mid">{payment.concept}</p>
      </div>
      <div className={classes.spaceBetweenFlexRow}>
        <b className="mid">{t('amount')}</b>
          <p style={{ color: theme.palette.primary.main }} className='mid'>{payment.scaledAmount} {logo.currency}</p>
      </div>
    </div>
  );
};

export const PayFormBody = withTranslation('translation')(
  _PayFormBody
);
