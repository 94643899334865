import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { REC_SCALE_FACTOR } from '../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import { logo } from '../styles/theme/theme';

const _QrPageAmount = ({ payment }: any) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  return (
    <Box
      style={{
        margin: '15px 0',
        width: '250px',
        borderRadius: '2px',
      }}
    >
      <div
        className="column center"
        style={{ padding: '15px', width: 'auto' }}
      >
        <span
          style={{
            paddingBottom: '8px',
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
          }}
        >
          {t('total')}
        </span>

        <h2 className="no-padding" style={{ fontWeight: 200 }}>
          {payment && payment.amount / REC_SCALE_FACTOR} {logo.currency}
        </h2>
      </div>
    </Box>
  );
};

export const QrPageAmount = withTranslation('translation')(
  _QrPageAmount,
);
