import { useEffect, useState } from 'react';
import { AuthProvider } from '../providers/AuthProvider';
import { ApiProvider } from '../providers/ApiProvider';
import { sortByName } from '../utils';

export function useAccounts() {
  const [accounts, setAccounts]: any = useState();
  const [accountsError, setAccountsError]: any = useState(null);
  const [accountsLoading, setAccountsLoading]: any = useState(null);

  useEffect(() => {
    if (AuthProvider.isAuthenticated()) {
      setAccountsLoading(true);
      
      ApiProvider.getAccounts()
        .then((resp) => {
          const accounts = resp.elements
            .filter((el: any) => {
              return (
                el.roles.includes('ROLE_ADMIN') ||
                el.roles.includes('ROLE_WORKER')
              );
            })
            .map((el: any) => el.company);
          setAccounts(accounts.sort(sortByName));
          setAccountsError(null);
          setAccountsLoading(false);
        })
        .catch((error) => {
          setAccountsError(error);
          setAccountsLoading(false);
        });
    }
  }, []);

  return { accounts, accountsError, accountsLoading };
}
