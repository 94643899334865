import React from 'react';
import QRCode from 'qrcode.react';
import { generateQrData } from '../utils';

export const PaymentQR = ({ payment }: any) => {
  return (
    <div className="cardBody">
      {payment && (
        <QRCode
          level="M"
          size={200}
          value={generateQrData(
            payment.payment_address,
            payment.amount,
            payment.concept
          )}
        />
      )}
    </div>
  );
};
